import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import "./404.css"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Introuvable" lang="fr" />
    <main className="section-wrapper" id="notfound">
      <h1>404: Introuvable</h1>
      <p>Vous vous êtes perdu...</p>
    </main>
  </Layout>
)

export default NotFoundPage
